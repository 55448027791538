.create-training-container {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding: 20px;
  background-color: #eff3f8;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.create-training-container h1 {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.create-training-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
}

.form-group textarea {
  resize: none;
  height: 80px;
}

.form-group .required {
  color: #e74c3c;
}

.submit-button, .back-button {
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-button {
  background-color: #28a745;
}

.submit-button:hover {
  background-color: #218838;
}

.back-button {
  background-color: #6c757d;
}

.back-button:hover {
  background-color: #5a6268;
}

@media (max-width: 480px) {
  .create-training-container {
    padding: 15px;
  }

  .create-training-container h1 {
    font-size: 20px;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 8px;
    font-size: 12px;
  }

  .submit-button, .back-button {
    padding: 8px 12px;
    font-size: 14px;
  }
}
