
.agronomist-profile-container {
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    font-family: 'Arial, sans-serif';
}

.agronomist-profile-header {
    background-color: #2C3E50;
    padding: 20px;
    border-radius: 20px 20px 0 0;
    text-align: center;
    color: white;
}

.agronomist-profile-details {
    background-color: white;
    padding: 20px;
    margin-top: -40px;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    line-height: 1.6;
}

.logout-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #2C3E50;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
}