.create-monitor-container {
    max-width: 600px;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .create-monitor-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .back-button {
    font-size: 24px;
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .back-button:hover {
    color: #0056b3;
  }
  
  h1 {
    font-size: 24px;
    color: #333;
    margin: 0;
  }
  
  .create-monitor-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-input, .form-textarea, .form-file-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .form-textarea {
    resize: vertical;
    height: 100px;
  }
  
  .submit-btn {
    padding: 10px;
    background-color: #ffcc00;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-btn:hover {
    background-color: #ffdd33;
  }