.view-farmers-container {
    background-color: #f5f5f5;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .view-farmers-header {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .create-new-ad {
    background-color: white;
    color: #4CAF50;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
  }
  
  .farmers-list {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .farmer-card {
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .card-body {
    display: flex;
    justify-content: space-between;
  }
  
  .days-ago {
    color: gray;
    font-size: 14px;
  }
  
  .price {
    color: #4CAF50;
    font-weight: bold;
    font-size: 16px;
  }
  
  .view-farmers-footer {
    background-color: #e0e0e0;
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
  }
  
  .footer-icon {
    text-align: center;
    flex: 1;
    color: gray;
    font-size: 16px;
  }
  
  .footer-icon:hover {
    color: #4CAF50;
  }