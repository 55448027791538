.register-container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-size: cover;
  padding: 30px;
  border-radius: 10px;
  max-width: 400px;
  margin: auto;
  color: white;
  text-align: center;
}

.logo {
  width: 80px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

input, select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px;
  background-color: #ffcc00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #ffdd33;
}

button[type="button"] {
  background-color: #006400;
  margin: 5px;
}

button[type="button"]:hover {
  background-color: #008000;
}

button[type="submit"] {
  background-color: #008000;
}

button[type="submit"]:hover {
  background-color: #00a000;
}

h2 {
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(2, 2, 2, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position: relative;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(10, 89, 207, 0.2);
  padding: 20px;
  width: 90%;
  max-width: 400px;
  z-index: 1000;
  text-align: center;
  display: flex;
  flex-direction: column; /* Ensures vertical stacking */
  gap: 15px; /* Adds spacing between elements */
}

.popup button {
  margin-top: 20px; /* Adds space above the button */
}