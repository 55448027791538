.history-container {
    padding: 20px;
  }
  
  .history-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .monitoring-reports {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .monitoring-report-card {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .report-images img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
  }