.monitor-container {
  background-color: #eceaea;
  color: #010003;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.monitor-header h1 {
  font-size: 24px;
  font-weight: bold;
}

.monitor-header p {
  font-size: 18px;
  margin-bottom: 20px;
}

.create-monitor-btn {
  background-color: #068321;
  color: #e2e2e4;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.create-monitor-btn:hover {
  background-color: #FFC300;
}

.monitor-banner {
  background-color: #0dc016;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  text-align: center;
}

.monitor-banner h2 {
  font-size: 22px;
  margin-bottom: 10px;
}

.call-now-btn {
  display: inline-block;
  background-color: #FFD700;
  color: #1c0566;
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: bold;
}

.monitor-featured {
  margin-top: 20px;
}

.monitor-featured h2 {
  font-size: 22px;
  margin-bottom: 15px;
}

.featured-products {
  display: flex;
  justify-content: space-between;
}

.product-card {
  background-color: #86f3a7;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  flex: 1;
  margin: 0 5px;
}

.product-card img {
  width: 80%;
  border-radius: 10px;
}

.dashboard-footer {
  display: flex;
  background-color: #068321;
  justify-content: space-around;
  margin-left: -20px;
  margin-top: 20px;
}

.footer-icon {
  font-size: 24px;
  color: #4117ca;
  text-decoration: none;
}

.login-icon {
  font-size: 24px;
  color: #FFD700;
  text-decoration: none;
}