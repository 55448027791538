.container {
    background-color: #0a4e16;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-family: Arial, sans-serif;
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

button {
    background-color: #f38b4a;
    color: white;
    padding: 10px 20px;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #FF732C;
}

h2 {
    text-align: center;
    margin-top: 20px;
    font-size: 1.5em;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    background-color: #1e690f;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

li span {
    flex: 1;
}

.phone-icon {
    background-size: contain;
    width: 30px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
}