.dashboard-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .dashboard-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .dashboard-content {
    margin-bottom: 20px;
  }
  
  .action-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .action-card {
    flex: 1 1 30%;
    text-align: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f5f5f5;
  }
  
  .action-link {
    text-decoration: none;
    color: #333;
  }
  
  .action-link span {
    font-size: 2em;
    display: block;
    margin-bottom: 10px;
  }
  
  .dashboard-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    margin-left: -20px;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 -2px 5px rgba(9, 64, 245, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .dashboard-footer.hide {
    transform: translateY(100%);
  }