.login-container {
  background: linear-gradient(rgba(12, 173, 25, 0.5), rgba(10, 29, 9, 0.5));
  background-size: cover;
  padding: 30px;
  padding-top: 25%;
  border-radius: 10px;
  max-width: 400px;
  margin: auto;
  color: white;
  text-align: center;
}

.logo {
  width: 80px;
  margin-bottom: 20px;
}

h2 {
  font-family: 'Arial', sans-serif;
  margin-bottom: 24px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #0fb834;
  color: black;
}

button {
  padding: 10px;
  background-color: #ddb412;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: black;
}

button:hover {
  background-color: #ffdd33;
}

.register-link {
  margin-top: 10px;
  font-size: 14px;
}

.register-link a {
  color: #ffcc00;
  text-decoration: none;
}

.register-link a:hover {
  text-decoration: underline;
}

.role-buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.role-buttons button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #086b04;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

.role-buttons button:hover {
  background-color: #72e78b;
}
.dashboard-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: #086b04;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  color: white;
  font-size: 20px;
  z-index: 1000; 
}

.footer-icon {
  text-decoration: none;
  color: white;
  font-size: 24px;
}

.footer-icon:hover {
  color: #ffdd33;
}

.login-icon {
  font-size: 24px;
}