.farmer-profile-container {
    padding: 20px;
  }
  
  .farmer-profile-header {
    font-family: 'Arial, sans-serif';
    color: #4CAF50; /* Green color */
    text-align: center;
  }
  
  .farmer-profile-details {
    background: #0bb335;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    font-family: 'Arial, sans-serif';
    color: #333;
  }
  
  .farmer-profile-details p {
    margin: 10px 0;
  }
  .dashboard-footer {
    display: flex;
    justify-content: space-around;
    background-color: #0bb335;
    color: #fff;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  .footer-icon, .login-icon {
    font-size: 24px;
    color: #fff;
    text-decoration: none;
  }
  
  .footer-icon:hover, .login-icon:hover {
    color: #ddd;
  }