/* Training.css */
.training-container {
  background-color: #cceebf; /* Dark brown background */
  color: white; /* White text color */
  padding: 20px;
  font-family: Arial, sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.training-header {
  color: #000000;
  font-size: 28px;
  font-weight: bold;
  font-weight: 700;
  font-style: oblique;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin-bottom: 10px;
  text-align: center;
}

.training-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
}

.training-item {
  background-color: #26a50d; /* Light brown background for items */
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  flex-direction: column; 
  align-items: flex-start;
}

.training-session-header {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.training-session-header h4 {
  font-size: 18px;
  margin: 0 0 5px 0;
}

.training-session-header span {
  font-size: 14px;
  color: #fff;
}

.training-links a {
  display: block;
  margin-top: 10px;
  background-color: rgb(196, 168, 7);
  height: 30px;
  width: 150px;
  text-align: center;
  padding-top: 10px;
  border-radius: 20px;
  justify-content: space-between;
  color: #ffffff; /* Yellow for links */
  text-decoration: none;
  font-weight: bold;
}

.training-links a:hover {
  text-decoration: underline;
}

.dashboard-footer {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #2d1301; /* Darker brown for the footer */
}

.footer-icon, .login-icon {
  color: white;
  font-size: 24px;
  text-decoration: none;
}

.footer-icon:hover, .login-icon:hover {
  color: #ffc107; /* Yellow on hover */
}