.dashboard-container {
  padding: 20px;
  background-color: #f9f9f9;
  font-family: 'Arial', sans-serif;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 20px;
}

.dashboard-header {
  text-align: center;
  background-color: #32cd32;
  color: white;
  padding: 10px 0;
  border-radius: 10px;
}

.weather-section {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.weather-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 60px;
}

.weather-card img {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.diagnosis-button {
  display: block;
  background-color: #32cd32;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin: 20px auto;
  width: 90%;
  cursor: pointer;
}

.gallery-section, .trending-diseases-section, .monitor-section {
  margin-top: 20px;
}

.gallery-section h2,
.trending-diseases-section h2,
.monitor-section h2 {
  margin-bottom: 10px;
}

.gallery-images {
  display: flex;
  justify-content: space-around;
}

.gallery-images img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.disease-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.disease-card img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.monitor-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.create-monitor-btn {
  background-color: #32cd32;
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  width: 90%;
  margin: 0 auto;
  cursor: pointer;
  display: block;
}

.dashboard-footer {
  position: fixed;
  margin-left: -20px;
  bottom: 0;
  width: 95%;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 -2px -10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.dashboard-footer.hide {
  transform: translateY(100%);
}

.footer-icon, .login-icon {
  font-size: 30px;
  color: #fff;
  text-decoration: none;
}

.footer-icon:hover, .login-icon:hover {
  color: #ddd;
}
.dashboard-container {
  padding: 20px;
}

.trending-diseases-section {
  margin-top: 20px;
}

.diseases-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.disease-item {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
}

.disease-icon img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.disease-info {
  flex: 1;
  margin-left: 15px;
}

.disease-info h3 {
  font-size: 16px;
  margin: 0;
  font-weight: bold;
}

.disease-info p {
  font-size: 14px;
  margin: 5px 0;
  color: gray;
}

.disease-info span {
  font-size: 12px;
  color: darkgray;
}

.disease-actions {
  margin-left: auto;
}

.disease-actions .action-link {
  font-size: 20px;
  text-decoration: none;
  color: #007bff;
}

.disease-actions .action-link:hover {
  color: #0056b3;
}