.farmer-profile-container {
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    font-family: 'Arial, sans-serif';
}

.farmer-profile-header {
    background-color: #2c502f;
    padding: 20px;
    border-radius: 20px 20px 0 0;
    text-align: center;
    color: white;
}

.farmer-profile-details {
    background-color: white;
    padding: 20px;
    margin-top: -40px;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    line-height: 1.6;
}

.dashboard-footer {
    display: flex;
    justify-content: space-around;
    margin-left: -20px;
    background-color: #169e33;
    border-radius: 0 0 20px 20px;
}

.footer-icon, .login-icon {
    color: white;
    font-size: 24px;
    text-decoration: none;
}